.top-bar {
  box-sizing: border-box;
  border-bottom: 1px solid #11132d;
  background-color: #010312;
  color: #fff;
  padding: 1px 0;
  font-weight: 400;
  text-align: left;
  line-height: 1.7;
  color: #212121;
  font-family: Rubik;
  font-size: 15px;
  transition: height 0.7s;
}

.contact-links > li {
  padding-left: 2em;
}

.contact-links > li > a > i {
  transition: 0.3 color;
}
.contact-links > li > a > i:hover {
  color: #fff;
}
@media (max-width: 768px) {
  .contact-links {
    width: 100%;
  }
}

@media (max-width: 992px) {
  .top-bar {
    display: none;
  }
}
