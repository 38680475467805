.page-title-container {
  background-color: #0e0129;
  position: relative;
  /* z-index: -1; */
  background-size: cover;
  /* height: 7em; */
  transition: height 0.7s;
}

.page-title-content {
  height: 100%;
  color: #fff;
  text-align: center;
  display: grid;
  align-items: center;
  padding-left: 10em;
}

.page-title-content h2 {
  font-weight: 900;
}

.page-title-content ul {
  display: inline-flex;
  list-style-type: none;
  margin-bottom: 0;
}

.page-title-content ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  padding-right: 15px;
  padding-left: 15px;
  color: #ffffff;
}
.page-title-content ul li.active{
  padding-left: 2em;
  z-index: 1000;
}
.page-title-content ul li.active::before {
  background-color: #afcb1f;
  content: "";
  position: absolute;
  top: 12px;
  left: 20px;
  width: 10px;
  height: 10px;
  z-index: 10;
}

.page-title-content ul li a {
  color: #ffffff;
  font-size: 20px;
}

.page-title-content ul li.active {
  color: #afcb1f;
  font-size: 20px;
  text-transform:capitalize; 
  /* font-weight:600; */
}

 

.page-title-div {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.page-title-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  animation: blinker 2s linear 2s infinite;
}




@media only screen and (max-width: 800px) {
  .page-title-container {
    display: none;
    overflow: hidden;
  }
}
