.tiles-main,
.tiles-wrap-layer1,
.tiles-wrap-layer2,
.tiles-inner,
.tiles {
  pointer-events: none;
}

.honeycomb {
  display: flex;
  flex-wrap: wrap;

  width: 100%;
}

.tiles {
  position: relative;
  width: calc(100% / 6);
  perspective: 1000px;
}
.client-image {
  width: 100px;
}
@media only screen and (max-width: 800px) {
  .honeycomb {
    width: 80%;
  }
  .tiles {
    position: relative;
    width: calc(100% / 2);
    perspective: 1000px;
  }
  .client-image {
    width: 100%;
  }
  .tiles-main--inner p {
    font-size: 16px !important;
  }
}

@media only screen and (min-width: 800px) {
  .honeycomb {
    margin-left: 5em;
  }
  .tiles {
    position: relative;
    width: calc(100% / 4);
    perspective: 1000px;
  }
  .client-image {
    width: 100px;
  }
  .tiles-main--inner p {
    font-size: 12px !important;
  }
}

@media only screen and (min-width: 1200px) {
  .honeycomb {
    margin-left: 5em;
  }
  .tiles {
    position: relative;
    width: calc(100% / 6);
    perspective: 1000px;
  }
  .client-image {
    width: 100px;
  }
}
.tiles:not(:last-child) {
  margin-right: -2%;
}
.tiles:hover {
  z-index: 1000 !important;
}

.tiles:hover .tiles-inner {
  transform: rotateY(180deg);
}

.tiles-inner {
  height: 100%;
  position: absolute;
  text-align: center;
  top: 0;
  transition: transform 0.8s cubic-bezier(0.35, 0.1, 0.6, 1.51);

  z-index: 1;
  transform-style: preserve-3d;
  width: 100%;
}

.tiles-border {
  margin: 0 auto;
}
.tiles-border:before,
.tiles-border:after {
  content: "";
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
}
.tiles-border:before {
  transform: rotate(60deg) translate(18%, -18%);
}
.tiles-border:after {
  transform: rotate(-60deg) translate(18%, 18%);
}
.tiles-border,
.tiles-border:before,
.tiles-border:after {
  /* border-bottom: 1px solid #11132d; */
  /* border-top: 1px solid #fff; */
  cursor: pointer;
  display: block;
  padding-bottom: 100%;
  width: 58%;
  pointer-events: auto;
}

.tiles-wrap {
  overflow: hidden;
  position: absolute;
  top: 1px;
  transform: rotate(120deg);
  visibility: hidden;
}
.tiles-wrap.-front,
.tiles-wrap.-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.tiles-wrap.-back {
  transform: rotateY(180deg);
}
.tiles-wrap.-back .tiles-main {
  transform: rotate(120deg);
}

.tiles-wrap-layer1 {
  height: 100%;
  overflow: hidden;
  transform: rotate(-60deg);
  visibility: hidden;

  width: 100%;
}

.tiles-wrap-layer2 {
  height: 100%;
  overflow: hidden;
  position: relative;
  transform: rotate(-60deg);
  visibility: visible;

  width: 100%;
}

.tiles-main {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  mix-blend-mode: difference;
  left: 0;
  position: absolute;
  top: 0;
  transition: background 0.5s ease;

  display: flex;
  align-items: center;
  justify-content: center;
}

.tiles-main--inner {
  padding: 20px 70px;
}

.tiles-main--inner p {
  text-align: center;
  font-size: 16px;
  /* text-align: justify; */
}

.btn-custom-category {
  padding: 0.4em 1em;
  /* border: 3px solid #afcb1f; */
  position: relative;
  overflow: hidden;
  background-color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #010312;

  border-radius: 0;
}

.btn-custom-category::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #010312;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}
/* 
.btn-custom-category:hover::before {
  width: 105%;
  background: #010312;
} */

.btn-custom-category:hover {
  color: #fff;
  background: #010312;

  /* border: 3px solid #010312; */
}
/* .btn-custom-category.active::before {
  width: 105%;
  background: #010312;
} */

.btn-custom-category.active {
  color: #fff;
  background: #afcb1f;

  /* border: 3px solid #010312; */
}

.container-align {
  position: relative;
}
.container-client {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  gap: 50px;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.grid-item {
  display: flex;
  align-items: center;
  justify-items: center;
  height: 100%;
  border-right: 1px solid #d3d3d3;
  border-width: 1.5px;
  height: 100px;
}

.grid-item img {
  /* height: 70%; */
  margin-right: 50px;
}

.grid-item:nth-child(5),
.grid-item:nth-child(10),
.grid-item:nth-child(15),
.grid-item:nth-child(20),
.grid-item:nth-child(25),
.grid-item:nth-child(30),
.grid-item:nth-child(32) {
  border-right: none;
}
.flex {
  position: absolute;
  bottom: -10%;
  left: 40%;
  right: 40%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  margin-bottom: 20px;
}
.client-text h1 {
  font-size: 25px;
  font-weight: 200;
}
.flex > div {
  margin: 2px;
  padding: 4px;
  /* font-size: 40px; */
}

.client-text {
  margin-top: 20px;
  align-items: center;
  justify-content: end;
}

@media only screen and (max-width: 400px) {
  /* .container-client {
    margin-right: 20px;
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); */

  /* grid-template-columns: 200px 200px 200px 200px; */
  /* grid-gap: 50px;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    text-align: center;
    position: relative;
  } */

  .container-client {
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns */
    gap: 20px; /* Adjust the gap between items */
  }
}
@media only screen and (max-width: 800px) {
  .container-client {
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns */
    gap: 20px;
    /* Adjust the gap between items */
    /* padding-left: 45px; */
  }
  .container-align {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-right: 1px solid #d3d3d3;
    border-width: 1.5px;
    height: 100px;
  }
  .grid-item img {
    margin-right: 0;
  }
  .grid-item:nth-child(5),
  .grid-item:nth-child(15),
  .grid-item:nth-child(25) {
    border-right: 1px solid #d3d3d3;
  }
  .grid-item:nth-child(even) {
    border-right: none;
  }
  /* .flex {
    position: static;
    margin-top: 5em;
    align-items: center;

    left: auto;
    right: auto;
  } */
  .flex {
    position: relative;
    left: auto;
    right: auto;
    bottom: auto;
    margin-top: 50px;
    margin-right: -200px;
  }
}
