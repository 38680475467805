.services-icon {
  position: relative;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  transition: all 1s;
  margin-top: 7px;
  background-color: #66c4d0;
  fill: #3b4656;
  color: #3b4656;
}

.product-image {
  display: flex;
}

.services-text {
  padding-left: 20px;
  padding-bottom: 20px;
}

.services-text h3 {
  font-size: 20px;
  color: #000000;
  font-family: "Oswald", Sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.services-text p {
  white-space: pre-wrap;
}
