.col2 {
  padding: 0;
}

.expertise-text {
  color: #212529;
  text-align: justify;
}

.transform-img img {
  width: 90%;
}
.security-image{
  height: 100%;
  background-size: cover;
}