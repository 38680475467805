.footer-bottom-area {
  background-color: #0f052a;
  padding: 20px 0;
}

.content p {
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #ffffff;
  text-align: center;
}

.content p a:hover {
  color: #afcb1f;
}
