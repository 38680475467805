.choose-area {
  /* position: relative; */
  width: 100%;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  justify-content: center;
  align-items: center;
}

.choose-wrap {
  background-color: #010c16;
  max-width: 540px;
  padding: 15px 10px;

  z-index: 1;
  padding: 3em 2rem 3em 2em;
}

.choose-wrap h2 {
  color: #fff;
  font-size: 60px;
  font-family: "Barlow Condensed", sans-serif;
}

.choose-wrap p {
  color: #ffffff;
  margin-bottom: 20px;
}
