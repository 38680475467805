.footer-top-area {
  /* background-attachment: scroll; */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: relative;
  z-index: 1;
  background-color: #0e0129;
}
.footer-top-area {
  background-image: url("../Footer/footer-bg.jpg");
  background-attachment: fixed, scroll;
  background-repeat: no-repeat, repeat-y;
}

.footer-top-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0e0129;
  opacity: 0.9;
  z-index: -1;
}

.footer-container {
  padding-top: 100px;
  padding-bottom: 20px;
  margin-bottom: 1em;
}

.single-footer-widget h3 {
  font-size: 22px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  display: inline-block;
  color: #ffffff;
  position: relative;
}

.single-footer-widget h3::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 2px;
  background-color: #ffffff;
}

ul {
  padding: 0;
}

.menu {
  color: #fff;
}

.textwidget ul li {
  color: #fff;
}

.textwidget ul p {
  color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

.footer-row {
  padding: 30px;
}
