.inner-modal {
  position: fixed;
  top: 0;
  z-index: -4000;
  left: 0;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: all 350ms cubic-bezier(0.68, -0.55, 0.265, 1.18);
}

.inner-modal-open {
  z-index: 4000;
  position: fixed;
  height: 90vh;
  width: 100%;
  border-radius: 0px;
  left: 0;
  top: 9rem;
  /* transform: translate(-50%, -50%); */
  -webkit-box-shadow: 10px 10px 10px -8px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.165, 0.265, 1.55);
}

.content-wrapper {
  padding: 20px;
  text-align: center;
  visibility: visible;
  height: 70vh;

  opacity: 0;
  -webkit-transition: all 0.2s;
}

@media only screen and (max-width: 992px) {
  .inner-modal-open {
    top: 5.5rem;
  }
  .content-wrapper {
    height: 80vh;
  }
}
.content-open {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-out 350ms;
}

.fa-times-circle {
  z-index: 4000;
  font-size: 35px;
  color: #2c3e32;
  float: right;
  transition: opacity 0.3s;
  cursor: pointer;
}

.fa-times-circle:hover {
  animation: pulse2 0.3s 2;
}

.modal-content {
  padding-top: 3em;
  font-size: 20px;
  height: 100%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.model-image {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
}

.client-image {
  width: 10em;
}

.client-close-icon {
  position: fixed;
  right: 1em;
  top: 10rem;
  z-index: 10000;
}

.client-close-icon:hover {
  /* animation: pulse 0.3s infinite; */
  color: #2c3e32;
  cursor: pointer;
}

.client-about {
  padding: 1em 5em 3em 5em;
  text-align: justify;
  overflow-y: auto;
  height: 100%;
  white-space: pre-line;
}

@media only screen and (max-width: 800px) {
  .client-about {
    padding: 3em 0.3em 3em 0.3em;
  }
}
