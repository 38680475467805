.career-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.inspiraion-section {
  padding: 50px 15% 100px 15%;
}
.font-barlow {
  font-family: "Barlow Condensed", sans-serif;
}
.font-coda {
  font-family: "Coda", cursive;
}
.why-section {
  color: #fff;
  background-color: #0e0129;
  padding: 50px 15% 100px 15%;
}

.life-in-necurity-card {
}

.life-in-perks-card {
  padding: 50px 50px 50px 50px;
  background-color: #f2f2f2;
  width: 80%;
}

.life-in-perks-card > li {
  padding: 5px 5px 5px 5px;
  font-size: 1.2em;
}

.why-title {
  font-weight: 300;
  display: inline-block;
  padding-bottom: 5px;
  font-size: 65px;
  position: relative;
}

.why-title:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  bottom: 0;
  left: 0%;
  border-bottom: 1px solid #00ce1b;
}

.job-opportunies-section {
}

.job-card {
  padding-top: 10px;
  background-color: #f6f6f6;
  border-radius: 0;
  border: 0;
  border-top: 5px solid #afcb1f;
}

.job-card-footer {
  border: 0;
  background-color: #f6f6f6;
}

.description-text {
  text-align: justify;
}
