.services-details p {
  margin-bottom: 20px;
}

.services-2col {
  position: relative;
}

.services-details .choose-wrap-services {
  margin-bottom: 20px;
}

.services-details h3 {
  font-size: 1.5em;
  font-weight: 600;
}

.choose-wrap-services.serviceposter {
  background-color: #e3ebb7;
  padding: 30px;
}

.choose-wrap-services.serviceposter h2 {
  font-weight: 700;
  font-size: 1.5em;
}

.services-details-order {
  padding-left: 15px;
}

.services-details-order .services-list {
  padding-left: 0;
  list-style-type: none;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  margin-bottom: 30px;
}

.services-details-order .services-list li a {
  display: block;
  border-bottom: 1px solid #eeeeee;
  padding: 15px 30px;
  position: relative;
  color: #0e0129;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}

.services-details-order .services-list li a::before {
  content: "\f061";
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "fontawesome";
  font-size: 18px;
}

.services-details-order .services-list li a:hover {
  background-color: #afcb1f;
  color: #ffffff;
}

/* Contact Info */
.services-details-order .services-contact-info {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  margin-bottom: 30px;
  padding: 30px;
}

.services-details-order .services-contact-info ul li .icon {
  color: #afcb1f;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: normal;
  font-size: 25px;
}

.services-details-order .services-contact-info ul li {
  margin-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
  position: relative;
  color: #6b6b84;
  font-weight: 500;
  font-size: 15px;
  padding-left: 35px;
  padding-bottom: 20px;
}

.active-services {
  background-color: #afcb1f;
}

.services-details-order .services-contact-info ul li span {
  color: #0e0129;
  display: block;
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: 600;
}

.services-details-order .services-contact-info ul li a {
  display: block;
  color: #6b6b84;
  margin-bottom: 8px;
  text-decoration: none;
}

.services-details-order .services-contact-info h3 {
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
}

/* Related Posts */
.single-solutions::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(1, 4, 20, 0.99))
  );
  background-image: linear-gradient(
    to bottom,
    transparent,
    rgba(1, 4, 20, 0.99)
  );
  z-index: -1;
}

.related-posts {
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
}

.single-solutions {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.single-solutions:hover {
  transform: translateY(-5px);
}

.single-solutions:hover::after {
  height: 100%;
  top: 0;
  bottom: auto;
}

.single-solutions::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.8;
  z-index: -1;
  background-image: linear-gradient(to bottom right, #aacc00, #b3cc37);
  transition: all 0.5s;
}

.single-solutions .solutions-content {
  position: absolute;
  bottom: 0;
  padding: 30px;
}

.single-solutions h3 {
  color: #ffffff;
  margin-bottom: 13px;
}

.single-solutions p {
  color: #ffffff;
  font-size: 15px;
  margin-bottom: 15px;
}

.single-solutions .solutions-content a {
  text-decoration: none;
  color: #fff;
  font-size: 15px;
  margin-bottom: 15px;
}

.read-more {
  color: #afcb1f;
}
