.section-title h4 {
  font-size: 23px;
}

.navis {
  width: 100%;
  /* height: 480px; */
}

.navis-product p {
  text-align: left;
}
