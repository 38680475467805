.container {
  width: 100%;

  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;

  margin-top: 4em;
  margin-bottom: 4em;
}

.section-title {
  margin: -12px auto 60px;
  text-align: center;
  position: relative;
  max-width: inherit;
}

.section-title h2 {
  font-size: 40px;
  margin-bottom: 15px;
  position: relative;
  display: block;
  text-transform: capitalize;
  color: #0e0129;
  font-weight: 700;
}

.section-title p {
  text-align: justify !important;
  box-sizing: border-box;
  font-size: 15px;
  margin-bottom: 15px;
  overflow-wrap: break-word;
  line-height: 1.7;
}

.vision-card {
  border-radius: 10px;
  border: none;
}

.visions-body {
  padding: 3em 2em 3em 2em;
}
.visions-body div.card-title {
  font-size: 2.5em;
  font-family: "Barlow Condensed", sans-serif;
  padding-bottom: 0.5em;
}

.visions-body p {
  color: #0e0129;
  text-align: justify;
}
@media screen and (max-width: 576px) {
  .visions-body {
    padding: 3em 1em 3em 1em;
  }
}

/* 1200 */
