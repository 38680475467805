.single-features {
  background-color: #140f14;
  transition: all 1s ease;
  background: linear-gradient(to left, #101323 50%, #ffffff 50%) right;
  background-size: 200% 100%;
  padding: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
}

.single-features::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #afcb1f;
  transition: width 1s ease-out;
}
.single-features:hover::before {
  width: 100%;
}

.single-features h3 {
  color: #afcb1f;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 700;
  font-size: 22px;
}

.single-features h3 i {
  font-size: 30px;
  padding-right: 5px;
}

.single-features p {
  font-size: 15px;
  font-family: Rubik;
  color: #fff;
}

.single-features:hover h3 {
  color: #afcb1f;
}

.single-features:hover h3 i {
  color: #afcb1f;
}

.single-features:hover p {
  font-size: 15px;
  font-family: Rubik;
  color: #140f14;
}

.single-features:hover {
  background-position: left;
}
