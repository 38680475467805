.carousel-service{
  background-repeat: no-repeat;
  background-size: cover;
}
.carousel-service::before{
  content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #010a1b;
    opacity: 0.78;
}


.carousel-container img {
  width: 100%;
  opacity: 0.95;
  z-index: -1;
}

.carousel-container {
  /* position: relative; */
}

.carousel-services {
  color: #fff;
  font-weight: 700;
  font-size: 35px;
  padding-top: 2em;
  position: relative;
  z-index: 2!important;
}

.carousel-list {
  /* position: absolute; */
  /* left: 25px;
  top: 80px;
  right: 25px; */
  text-align: center;
  z-index: 1;
}

.carousel-list h3,
p {
  color: #0e0129;
}

.carousel-list h3 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
}

.fa-solid {
  font-size: 30px !important;
  font-weight: 600 !important;
}

.single-list-item {
  padding: 10px;
  background: linear-gradient(to left, #fff 50%, #afcb1f 50%) right;
  height: 300px;
  width: 90%;
  transition: all 1s ease;
  color: #0e0129;
  background-size: 200% 100%;
}

.single-list-item:hover {
  background-position: left;
}

.single-list-item h3 {
  font-size: 23px;
}

.single-list-item p {
  justify-content: center;
  color: #0e0129;
}

.single-list-item:hover i {
  color: #fff;
  transform: rotateY(720deg);
}

.single-list-item:hover h3 {
  color: #fff;
}

.single-list-item:hover p {
  color: #fff;
}

.single-list-item:hover a {
  color: #000000;
}

.slick-slide > div {
  margin: 0 10px;
  width: 90%;
}

.slick-list {
  margin: 0 -10px;
}

.slick-arrow::before {
  color: #afcb1f !important;
}

.slick-dots li button:before {
  color: #afcb1f !important;
}
