.contact-input {
  hyphens: manual;
  margin: 0;
  font-family: inherit;
  overflow: visible;
  display: block;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: none;
  min-height: 50px;
  color: #0e0129;
  border: 1px solid #e8e8e8;
  background-color: #f2f9fc;
  border-radius: 0;
  font-size: 14px;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
  direction: ltr;
  transition: 0.3 all;
}

.contact-input:hover,
.contact-input:focus,
.contact-input:active,
.contact-input:focus-visible {
  border: 1px solid #afcb1f;
  background-color: #ffffff;
}

.contact-btn {
  padding: 0.8em 1.8em;
  border: 2px solid #afcb1f;
  position: relative;
  overflow: hidden;
  background-color: #afcb1f;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #ffffff;
  border-radius: 0;
}

.contact-btn::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #0e0129;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.contact-btn:hover::before {
  width: 105%;
}

.contact-btn:hover {
  color: #ffffff;
  border: 2px solid #afcb1f;
}
