.tab-title {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.02);
  background-color: #f1f1f1;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.method-list-title {
  flex-grow: 1;
  margin: 0;
  padding-left: 10px;
}

.icon {
  font-size: 20px;
}

.tab-title:hover {
  background-color: #0e042a;
}

.tab-title:hover i {
  color: #fff;
}

.tab-title:hover span {
  color: #fff;
}

/* OnClick */

.onclick-tab {
  border: 1px solid #eee;
  padding: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 1rem;
  line-height: 1.7;
  color: #333;
}

.onclick-tab p {
  color: #0e042a;
}
