.mission {
  background-color: #010c16;
  position: relative;
  z-index: -1;
  background-size: cover;
  height: 100%;
  padding: 70px;
}

.about-us-h2 p {
  color: #0e0129;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.electronic-content .tab .tabs_item:first-child {
  display: block;
}

.electronic-content .electronic-tab-wrap .electronic-tab p {
  color: #ffffff;
}
.para-align {
  color: #fff;
  text-align: justify;
}

p:last-child {
  margin-bottom: 0;
}

.electronic-img {
  text-align: center;
}

.align-items-center {
  align-items: center;
}

p {
  font-size: 15px;
  margin-bottom: 15px;
}

/* Section-title  */

.section-title h1 {
  color: #0e0129;
  font-weight: 800;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: capitalize;
  padding-top: 70px;
  font-size: 30px;
}

.section-title h2 {
  font-size: 40px;
  margin-bottom: 15px;
  position: relative;
  display: block;
  text-transform: capitalize;
}

.section-title p {
  text-align: center !important;
  color: #0e0129 !important;
}

/* Section Rows */
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.single-security::before {
  background-image: linear-gradient(to bottom right, #afcb1f, #d3ed4f);
  content: "";
  position: relative;
  top: 0;
  right: 0;
  width: 0;
  height: 100%;
  background-position: right bottom;
  transition: all 0.5s;
  z-index: -1;
}

.complete-area.style .single-security {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.single-security {
  background: #afcb2045;
  background: linear-gradient(to left, #c5df4645, #d3ed4f) right;
  background-size: 200% 100%;
  padding: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  height: 100%;
}

.single-security:hover {
  background-position: left;
  transform: scale(1.1);
}

.single-security h3 {
  font-size: 22px;
  margin-bottom: 9px;
  color: #0e0129;
  line-height: 1.2;
}

.single-security p {
  color: #0e0129;
}

.single-security:hover h3 {
  color: #fff;
}

.single-security:hover p {
  color: #fff;
}

.single-security:hover .fa-solid {
  color: #fff;
  transform: rotateY(720deg);
}

/* Fonts */

.fa-solid {
  font-size: 40px;
  color: #afcb1f;
  padding-bottom: 15px;
  transition: transform 1s ease-in-out;
}

.col h4 {
  color: #0e0129;
  font-weight: 900;
  font-family: "Barlow Condensed", sans-serif;
  text-transform: capitalize;
  font-size: 1.15rem;
}
