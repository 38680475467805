.banner {
  height: 90.3vh;
  width: 100%;
  background-attachment: scroll;
  background-size: cover;
  object-fit: cover;
  padding-left: 0;
  padding-right: 0;
  display: grid;
  justify-content: center;
  align-items: center;
}

.bannerText h1 {
  font-size: 60px;
  font-family: "Barlow Condensed", sans-serif;

  color: #ffffff;
  margin-bottom: 25px;
  line-height: 1.1;
  text-transform: capitalize;
  font-weight: 600;
  width: 50%;
}

.bannerText p {
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 35px;
  overflow-wrap: break-word;
}

.about {
  margin-left: 30px;
}

.blink {
  width: 100%;
  height: 91.6vh;
  position: absolute;
  top: 100px;
  left: 0;
  animation: blinker 1s linear 1s infinite;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

a:hover {
  color: #fff;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.btn-custom-white {
  padding: 0.8em 1.8em;
  border: 0px solid #fff;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #0e0129;
  border-radius: 0;
}

.btn-custom-white::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #0e0129;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.btn-custom-white:hover::before {
  width: 105%;
  background: #0e0129;
}

.btn-custom-white:hover {
  color: #fff;
  background: #0e0129;

  border: 0px solid #fff;
}

@media screen and (max-width: 900px) {
  .blink {
    overflow: hidden;
  }

  img {
    width: 100%;
    overflow: visible;
  }

  .bannerText h1 {
    font-size: 40px;
  }
}



/* Down Arrow */

.arrows {
	width: 60px;
	height: 72px;
	position: absolute;
	left: 50%;
	margin-left: -30px;
	bottom: 80px;
}

.arrows path {
	stroke: #66c4d0;
	fill: transparent;
	stroke-width: 2px;	
	animation: arrow 2s infinite;
	-webkit-animation: arrow 2s infinite; 
}

@keyframes arrow
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0}
40% {opacity:1}
80% {opacity:0}
100% {opacity:0}
}

.arrows path.a1 {
	animation-delay:-1s;
	-webkit-animation-delay:-1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
	animation-delay:-0.5s;
	-webkit-animation-delay:-0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {	
	animation-delay:0s;
	-webkit-animation-delay:0s; /* Safari 和 Chrome */
}