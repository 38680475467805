li {
  list-style: none;
}

ul {
  overflow-wrap: break-word;
}


.cybersecurity-item {
  display: flex;
  flex-direction: column;
}

.cybersecurity-item div div {
  display: flex;
  justify-content: start;
  justify-items: center;
  align-items: center;
  background-color: #afcb2045;
  padding: 10px;
  margin-bottom: 15px;
  width: 100%;
  font-weight: 600;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 4px;

  height: 75px;

}

.cybersecurity-item div div i {
  background-color: #d5e971;
  margin-right: 1.2em;
  font-weight: 600;
}

.cybersecurity-item div div:hover i {
  color: #fff;
  transition: color 0.3s;
}

.bx {
  display: inline-block;
  line-height: 1;
  color: #afcb1f;
  font-size: 20px;
}

.bx-check::before {
  content: "\ea41";
  padding: 0;
}
