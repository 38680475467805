.aboutus {
  padding-top: 10%;
  padding-left: 5%;
  padding-bottom: 5%;
}

.col2 h2 {
  font-weight: 700;
}

.about-list li {
  background-color: #afcb2045;
  padding: 25px;
  position: relative;
  padding-left: 100px;
  margin-bottom: 30px;
  transition: all 0.5s;
}

.about-list li:hover {
  transform: scale(1.1);
}

.aboutus ul li i {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px solid;
  text-align: center;
  border-radius: 50%;
  transition: all 1s;
}

.about-list li:hover i {
  color: #0a0629;
  fill: #0a0629;
}

.about-list li h3 {
  font-size: 20px;
  font-weight: 700;
}

.about-list li p {
  font-size: 15px;
}
