@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,700;0,800;0,900;1,400;1,800&family=Rubik:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Coda&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Rubik", sans-serif;
  line-height: 1.7;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.btn-custom {
  padding: 0.8em 1.8em;
  border: 0px solid #afcb1f;
  position: relative;
  overflow: hidden;
  background-color: #afcb1f;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #ffffff;
  border-radius: 0;
}

.btn-custom::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #0e0129;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.btn-custom:hover::before {
  width: 105%;
}

.btn-custom:hover {
  color: #ffffff;
  border: 0px solid #afcb1f;
  background: #0e0129;
}

/* Animations */

@-webkit-keyframes blinker {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes blinker {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
