.aboutus {
  padding-top: 10%;
  padding-left: 5%;
  padding-bottom: 5%;
}

.aboutus p {
  color: #212529;
  text-align: justify;
}
