.perc {
  display: flex;
}

.perc h4 {
  font-size: 20px;
  font-weight: bold;
}

.progress-bar {
  height: 7px;
  background-color: #ebe8e8;
  border-radius: 5px;
}

.perGap {
  padding-left: 75%;
}

.progress-bar div {
  height: 6px;
  border-radius: 5px;
  width: 98%;
  background: #afcb1f;
}
