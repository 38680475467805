.navContainer {
  padding: 15px 40px;
  background-color: #010312;
  z-index: 100;
}

.nav-burger-menu {
  color: #afcb1f;
  transition: 0.3s color;
  border-radius: 2px;
  
  
}
.nav-burger-menu:hover {
  color: #fff;

 
}

button.nav-burger-menu{
  border: 3px solid transparent;
}

button.nav-burger-menu:hover{
  color: #fff;
  
}

button.nav-burger-menu:focus{
  /* border: 1px solid white; */
}
/* .bxs-phone-call {
  font-size: 35px;
  color: #afcb1f;
} */

.call-us {
  display: inline-block;
  padding: 0 0 0 45px;
}

.call-us a p {
  color: #fff;
  display: inline-block;
  position: relative;
  line-height: 1;
  padding-left: 10px;
  font-size: 18px;
}

a i {
  vertical-align: middle;
}

.logo {
  width: auto;
  height: 50px;
}
.nav-items {
  color: #fff;
  padding: 10px;
}

.nav-items:hover {
  color: #afcb1f;
}

.active {
  color: #afcb1f;
}

@media screen and (max-width: 960px) {
  .bxs-phone-call {
    display: none;
  }

  .call-us {
    display: none;
  }
}
.bxs-phone-call {
  color: #fff;
  font-size: 25px;
}
.bxs-phone-call:hover {
  color: #fff;
}
.btn-custom-phone {
  padding: 0.4em 1em;
  border: 3px solid #afcb1f;
  position: relative;
  overflow: hidden;
  background-color: #afcb1f;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #fff;

  border-radius: 0;
}

.btn-custom-phone::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #010312;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
}

.btn-custom-phone:hover::before {
  width: 105%;
  background: #010312;
}

.btn-custom-phone:hover {
  color: #fff;
  background: #010312;

  border: 3px solid #010312;
}
